import store from '@/store'
import { ref, watch } from '@vue/composition-api'

export default function useproblemsList() {
  const problemListTable = ref([])

  const tableColumns = [
    { text: 'Reporter NAME', value: 'name' },
    { text: 'note', value: 'note' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const searchQuery = ref('')
  const phoneFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)
  const totalproblemListTable = ref(0)
  const loading = ref(false)
  const options = ref({
    sortBy: ['id'],
    sortDesc: [true],
  })
  const problemTotalLocal = ref([])
  const selectedRows = ref([])

  // fetch data
  const fetchProblems = () => {
    options.value.q = searchQuery.value
    store
      .dispatch('app-problem/fetchProblems', {
        q: searchQuery.value,
        options: options.value,
        status: statusFilter.value,
        phone: phoneFilter.value,
        plan: planFilter.value,
      })
      .then(response => {
        const { filteredData, total } = response.data.data
        problemListTable.value = filteredData
        console.log('problemListTable.value', problemListTable.value)
        totalproblemListTable.value = total

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.error(error)
      })
  }

  watch([searchQuery, phoneFilter, planFilter, statusFilter, options], () => {
    loading.value = true
    selectedRows.value = []
    fetchProblems()
  })
  return {
    problemListTable,
    tableColumns,
    searchQuery,
    phoneFilter,
    planFilter,
    statusFilter,
    totalproblemListTable,
    loading,
    options,
    problemTotalLocal,
    selectedRows,
    fetchProblems,
  }
}
