<template>
  <div id="problem-list">
    <!-- list filters -->
    <v-card>
      <v-card-title> Problems </v-card-title>

      <v-divider class="mt-4"></v-divider>

      <!-- actions -->
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <!-- search -->
        <!-- <v-text-field
          v-model="searchQuery"
          placeholder="Search"
          outlined
          hide-details
          dense
          class="problem-search me-3 mb-4"
        >
        </v-text-field> -->

        <v-spacer></v-spacer>

        <!-- <div class="d-flex align-center flex-wrap">
          <v-btn color="secondary" outlined class="mb-4">
            <v-icon size="17" class="me-1">
              {{ icons.mdiExportVariant }}
            </v-icon>
            <span>Export</span>
          </v-btn>
        </div> -->
      </v-card-text>

      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        :headers="tableColumns"
        :items="problemListTable"
        :options.sync="options"
        :server-items-length="totalproblemListTable"
        :loading="loading"
        @refetch-data="fetchProblems"
      >
        <!-- plan -->
        <template #[`item.name`]="{ item }">
          <router-link
            :to="`/apps/user/view/${item.user_id}`"
            class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
          >
            <span class="text-capitalize font-weight-semibold text--primary">{{
              item.user.first_name + ' ' + item.user.last_name
            }}</span>
          </router-link>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{ item }">
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item :to="`/apps/orders/view/${item.order_id}`">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>Details</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import {
  mdiAccountOutline,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiExportVariant,
  mdiFileDocumentOutline,
  mdiPlus,
  mdiSquareEditOutline,
} from '@mdi/js'
import { onUnmounted } from '@vue/composition-api'

// sidebar
import problemStoreModule from '../problemStoreModule'
import useProblemList from './useProblemList'

export default {
  setup(props, { emit }) {
    const Problem_APP_STORE_MODULE_NAME = 'app-problem'

    // Register module
    if (!store.hasModule(Problem_APP_STORE_MODULE_NAME)) {
      store.registerModule(Problem_APP_STORE_MODULE_NAME, problemStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(Problem_APP_STORE_MODULE_NAME)) store.unregisterModule(Problem_APP_STORE_MODULE_NAME)
    })
    const {
      problemListTable,
      totalproblemListTable,
      problemTotalLocal,
      fetchProblems,
      tableColumns,
      searchQuery,
      roleFilter,
      planFilter,
      statusFilter,
      loading,
      options,
      selectedRows,
    } = useProblemList()

    return {
      problemListTable,
      tableColumns,
      searchQuery,
      roleFilter,
      planFilter,
      statusFilter,
      totalproblemListTable,
      loading,
      options,
      problemTotalLocal,
      selectedRows,
      fetchProblems,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
